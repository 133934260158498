import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/news.vue'),
    },
    {
        path: '/products-js',
        name: 'products-js',
        component: () => import('../views/products-js.vue'),
    },
    {
        path: '/products-fc',
        name: 'products-fc',
        component: () => import('../views/products-fc.vue'),
    },
    {
        path: '/productsDetail',
        name: 'productsDetail',
        component: () => import('../views/productsDetail.vue'),
    },
    {
        path: '/property',
        name: 'property',
        component: () => import('../views/property.vue'),
    },
    {
        path: '/business',
        name: 'business',
        component: () => import('../views/business.vue'),
    },
    {
        path: '/estate',
        name: 'estate',
        component: () => import('../views/estate.vue'),
    },
    {
        path: '/culture',
        name: 'culture',
        component: () => import('../views/culture.vue'),
    },
    {
        path: '/hr',
        name: 'hr',
        component: () => import('../views/hr.vue'),
    },
    {
        path: '/xilie',
        name: 'xilie',
        component: () => import('../views/xilie.vue'),
    },
]

const router = new VueRouter({
    routes
})

export default router
