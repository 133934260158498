import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest,getList,base} from "@/api/api";
import ElementUI from 'element-ui';//导入element-ui
import 'element-ui/lib/theme-chalk/index.css';//引入全部组件

Vue.use(ElementUI);
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });//设置全局配置对象
Vue.prototype.getRequest = getRequest;
Vue.prototype.getList = getList;

// 定义全局URL
Vue.prototype.$apiUrl = base;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
